import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import "react-id-swiper/lib/styles/css/swiper.css"
import { Link } from "gatsby"

const DataPage = location => {
  return (
    <Layout location={location} page="technology-data">
      <div className="data-page" style={{ paddingTop: "100px" }}>
        <div className="data-heading">
          <div className="container">
            <h1 className="text-blue text-white text-center mb-5 relative">
              Data
            </h1>
            <h3 className="relative text-white mb-4">
              Access skilled data professionals. Grow your career.
            </h3>
            <div className="flex flex-wrap justify-content-between align-items-center">
              <Link
                className="white-btn block relative mr-md-2"
                to="/contact-us"
              >
                Find Data Talent
              </Link>{" "}
              <Link className="white-btn block relative" to="/jobs">
                Find Data Jobs
              </Link>
            </div>
          </div>
        </div>
        <Container>
          <div className="py-100">
            <Row className="justify-content-center">
              <Col lg={9}>
                <h3>Driving Data Solutions</h3>
                <p>
                  Complex data problems require complex data solutions – and the
                  right people in the right seats to make those solutions
                  happen.
                </p>
                <p>
                  From pipeline development to predictive analytics, or data
                  modeling to cloud migration, our data experts know this
                  industry. And they’re ready to hit the ground running as an
                  embedded part of your team; all you need to do is get in touch
                  with PeerSource, and we’ll make the process simple and
                  streamlined.
                </p>
                <p>
                  We supply your company with the skilled professionals you need
                  for successful outcomes across a wide range of data
                  technologies, including:{" "}
                </p>
                <Row className="justify-content-center">
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>SQL Server</li>
                      <li>Oracle</li>
                      <li>MySQL</li>
                      <li>PostgreSQL</li>
                      <li>MongoDB</li>
                      <li>Cassandra</li>
                      <li>Redis</li>
                      <li>Azure Synapse</li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Amazon Redshift</li>
                      <li>Snowflake</li>
                      <li>Tableau</li>
                      <li>Power BI</li>
                      <li>QlikView</li>
                      <li>MicroStrategy</li>
                      <li>Domo</li>
                      <li>Informatica</li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Talend</li>
                      <li>Oracle Data Integrator</li>
                      <li>SSIS</li>
                      <li>Pentaho Data Integration</li>
                      <li>TensorFlow</li>
                      <li>Pandas</li>
                      <li>Spark</li>
                      <li>Kafka</li>
                    </ul>
                  </Col>
                </Row>
                <Link
                  className="common-btn block relative mb-5"
                  to="/contact-us"
                >
                  Hire Data Talent
                </Link>
                <h4 className="mb-4">The Data Professionals We Place</h4>
                <Row className="justify-content-center mb-5">
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Data Analyst</li>
                      <li>Database Developer</li>
                      <li>ETL Developer</li>
                      <li>Data Engineer</li>
                      <li>Data Architect</li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Database Administrator</li>
                      <li>MDM Specialist</li>
                      <li>BI Developer</li>
                      <li>Data Scientist</li>
                      <li>Artificial Intelligence Engineer</li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Machine Learning Engineer</li>
                      <li>Computer Vision Engineer</li>
                      <li>Prompt Engineer</li>
                      <li>Management and Executive Leadership</li>
                    </ul>
                  </Col>
                </Row>
                <h3>Ready to Get to Work?</h3>
                <p>
                  When it’s time to take the next step in your data career, turn
                  to PeerSource. With us, you get a better recruiting experience
                  – we work closely with you to understand what motivates you,
                  what excites you, and where you want your career to go. And we
                  won’t rest until you’re completely satisfied with your next
                  job opportunity.
                </p>
                <p>
                  No matter where you are in your data career – or where you’d
                  like to go next – we'll be by your side, every step of the
                  journey. Explore our openings and apply today.
                </p>
                <Link className="common-btn block relative" to="/jobs">
                  Browse Jobs
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
        <Container
          fluid
          className="benefit-tech text-center"
          style={{ padding: "100px 0", "background-color": "#0f2848" }}
        >
          <Container>
            <h2 className="text-white">Get Started with PeerSource!</h2>
            <h3
              className="text-center btech-flair mb-5 text-white text-center"
              style={{ maWidth: "33em", margin: "0 auto" }}
            >
              How can we help?
            </h3>
            <div className="flex flex-wrap justify-content-between align-items-center">
              <Link
                className="white-btn border-none mr-md-2"
                to="/contact-us"
                style={{ fontSize: "20px" }}
              >
                Find Professionals
              </Link>
              <Link
                className="white-btn border-none"
                to="/jobs"
                style={{ fontSize: "20px" }}
              >
                Browse Jobs
              </Link>
            </div>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export default DataPage
